import React from "react";
import Section from "@/components/Section/section";
import Components from "@/components/components";
import SbEditable from "storyblok-react";
import styles from "./section.module.scss";
import classnames from "classnames";
import SectionItem from "./section-item";
import { SectionStoryblok } from "@/types/component-types-sb";

interface Props {
  blok: SectionStoryblok;
  className?: string;
}

const SbSection = (props: Props) => {
  const classes = classnames(
    styles[props.blok.padding_top || ""],
    styles[props.blok.padding_bottom || ""],
    props.className
  );

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <Section {...props.blok} className={classes} name={props.blok.name}>
        {props?.blok?.content?.map((blok) => {
          return (
            <SectionItem key={blok._uid} {...blok} className={blok.component}>
              {React.createElement(Components(blok.component), {
                blok,
              })}
            </SectionItem>
          );
        })}
      </Section>
    </SbEditable>
  );
};

export default SbSection;
