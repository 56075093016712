import React, { useContext } from "react";
import Components from "@/components/components";
import styles from "./section.module.scss";
import classnames from "classnames";
import { bpInRange, BreakpointContext } from "@/context/BreakpointContext";
import { aspectToPadding } from "../../helpers/utils";
import { SectionStoryblok } from "@/types/component-types-sb";

type Props = SectionStoryblok;

const Section = (props: Partial<Props>) => {
  const {
    children,
    background_color,
    background_media_top,
    unpad_background_media_top,
    background_media_bottom,
    unpad_background_media_bottom,
    background_gradient_from,
    background_gradient_to,
    invert_color,
    name,
    rounded_top,
    rounded_bottom,
    inner_shadow_top,
    inner_shadow_bottom,
    className,
  } = props;
  const { currentBp } = useContext(BreakpointContext);
  const smallViewport = bpInRange({ currentBp, bp: "m", range: "down" });

  const currentBgAspectTop = smallViewport
    ? background_media_top?.[0]?.aspect_ratio_mobile
    : background_media_top?.[0]?.aspect_ratio_desktop ||
      background_media_top?.[0]?.aspect_ratio_mobile ||
      null;
  const currentBgAspectBottom = smallViewport
    ? background_media_bottom?.[0]?.aspect_ratio_mobile
    : background_media_bottom?.[0]?.aspect_ratio_desktop ||
      background_media_bottom?.[0]?.aspect_ratio_mobile ||
      null;
  const currentBgPaddingTop =
    currentBgAspectTop && !unpad_background_media_top
      ? { paddingTop: `${aspectToPadding(currentBgAspectTop)}%` }
      : null;
  const currentBgPaddingBottom =
    currentBgAspectBottom && !unpad_background_media_bottom
      ? { paddingBottom: `${aspectToPadding(currentBgAspectBottom)}%` }
      : null;
  const padding = { ...currentBgPaddingTop, ...currentBgPaddingBottom };

  const hasBg =
    background_gradient_from?.color ||
    background_gradient_to?.color ||
    background_color?.color ||
    background_media_top?.[0] ||
    background_media_bottom?.[0];

  const classes = classnames(
    styles.layoutSection,
    "layout-section",
    hasBg ? "with-bg" : "",
    invert_color ? styles.inv : "",
    name ? `section-${name}` : "",
    rounded_top ? styles.roundedTop : "",
    rounded_bottom ? styles.roundedBottom : "",
    inner_shadow_top ? styles.shadowTop : "",
    inner_shadow_bottom ? styles.shadowBottom : "",
    className
  );

  const bgClass = classnames(name ? `section-${name}-bg` : "");

  return (
    <section className={classes} style={padding}>
      {hasBg ? (
        <div className={classnames(styles.sectionBg, "section-bg")}>
          <SectionBackground
            background_gradient_from={background_gradient_from}
            background_gradient_to={background_gradient_to}
            background_color={background_color}
            className={bgClass}
          />
          <SectionBackgroundMedia
            top
            background_media={background_media_top}
            background_color={background_color}
            background_gradient_to={background_gradient_to}
          />
          <SectionBackgroundMedia
            bottom
            background_media={background_media_bottom}
            background_color={background_color}
            background_gradient_to={background_gradient_to}
          />
        </div>
      ) : null}
      {children}
    </section>
  );
};

interface SectionBackgroundMediaProps {
  background_media:
    | SectionStoryblok["background_media_top"]
    | SectionStoryblok["background_media_bottom"];
  background_gradient_to: SectionStoryblok["background_gradient_to"];
  background_color: SectionStoryblok["background_color"];
  top?: boolean;
  bottom?: boolean;
}

const SectionBackgroundMedia = (props: SectionBackgroundMediaProps) => {
  const {
    background_media,
    background_gradient_to,
    background_color,
    top,
    bottom,
  } = props;
  const classes = classnames(
    styles.backgroundMedia,
    top ? styles.top : "",
    bottom ? styles.bottom : ""
  );

  return background_media?.[0] ? (
    <div
      className={classes}
      style={{
        ["--overlay-from" as string]:
          background_gradient_to?.color || background_color?.color,
      }}
    >
      {background_media?.[0]
        ? React.createElement(Components(background_media[0].component), {
            key: background_media[0]._uid,
            blok: background_media[0],
            className: styles.backgroundMedia,
          })
        : null}
    </div>
  ) : null;
};

interface SectionBackgroundProps {
  background_gradient_from: SectionStoryblok["background_gradient_from"];
  background_gradient_to: SectionStoryblok["background_gradient_to"];
  background_color: SectionStoryblok["background_color"];
  className?: string;
}

const SectionBackground = (props: SectionBackgroundProps) => {
  const { background_gradient_from, background_gradient_to, background_color } =
    props;
  const hasColor =
    (background_gradient_from?.color && background_gradient_to?.color) ||
    background_color?.color;
  return hasColor ? (
    <div
      suppressHydrationWarning
      className={styles.backgroundColor}
      style={{
        ["--gradient-from" as string]: background_gradient_from.color,
        ["--gradient-to" as string]: background_gradient_to.color,
        ["--bg-color" as string]: background_color.color,
      }}
    />
  ) : null;
};

export default Section;
