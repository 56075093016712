import React from "react";
import styles from "../Layout/layout.module.scss";
import classnames from "classnames";
import { SectionStoryblok } from "@/types/component-types-sb";

type Item = NonNullable<SectionStoryblok["content"]>[number];

type Props = Item & {
  className?: string;
};

const SectionItem = (props: Props) => {
  const {
    grid_line_start,
    grid_line_end,
    grid_line_start_s,
    grid_line_end_s,
    align_horizontal,
    align_vertical,
    space_above,
    space_above_l,
    children,
    className,
  } = props;
  const classes = classnames(
    styles.layoutComponent,
    styles[space_above],
    styles[space_above_l],
    className
  );
  const allStyles = {
    ["--col-start-s" as string]: grid_line_start_s,
    ["--col-end-s" as string]: grid_line_end_s,
    ["--col-start" as string]: grid_line_start,
    ["--col-end" as string]: grid_line_end,
    ["--align-x" as string]: align_horizontal,
    ["--align-y" as string]: align_vertical,
  };
  return (
    <div className={classes} style={allStyles} suppressHydrationWarning>
      {children}
    </div>
  );
};

export default SectionItem;
